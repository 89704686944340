<template>
  <div>

    <div class="display_flex justify-content_flex-justify align-items_center padding-lr-sm padding-tb">
      <div class="totalCh">魅力总值：<span>{{ total_charm }}</span></div>
      <img v-if="is_display_button == 1&&total_charm!=0" class="total_zero" src="img/zero.png" alt="" @click="showPopup">
    </div>

    <div class="charmList padding-top-sm">

      <div class="pk_noData" v-if="charm_list.length == 0">
        <img src="img/noData.png" alt="">
      </div>

      <div class="display_flex justify-content_flex-justify align-items_center padding-sm"
        v-for="(item, index) in charm_list" :key="index">
        <div class="display_flex align-items_center">
          <div class="cha-order">
            <img v-if="index < 3" class="cha-num" :src="'img/' + (index + 1) + '.png'" alt="">
            <span v-else>{{ index + 1 }}</span>
          </div>
          <img class="cha-img margin-left" :src="item.head_portrait ? item.head_portrait : 'img/nickPic.png'" alt=""
            @click="checkUser(item.user_id)">
          <div class="margin-left-sm">
            <div class="display_flex align-items_center">
              <div class="cha-name">{{ item.nickname }}</div>
              <div class="cha-level margin-left-xs"
                :style="{ backgroundImage: 'url(' + 'img/level/level0/' + item.charm_level + '.png' + ')' }"></div>
            </div>
            <div class="cha-id margin-top-xs">id:{{ item.uid }}</div>
          </div>
        </div>
        <div class="cha-weal display_flex flex-direction_column align-items_flex-end">
          <span>魅力值：{{ item.charm_value }}</span>
          <div class="cha-zero margin-top-xs" style="background-image: url('img/zero_clear.png')"
            v-if="is_display_button == 1" @click="clearOne(item.user_id, index)"></div>
        </div>
      </div>

    </div>

    <van-popup v-model="show" round :style="{ width: '80%' }">
      <div class="clearTxt margin-top">提示</div>
      <div class="clearTxt margin-top padding-lr-sm">您当前有多条魅力值记录，一键操作数据将会归零,您确认要继续清空吗</div>
      <div class="clearBtn display_flex align-items_center margin-top-xl">
        <div class="flex_1 clear_qx" @click="hidePopup">取消</div>
        <van-button class="flex_1 clear_qd" :disabled="isdisabled" @click="clearAll()">继续</van-button>
      </div>
    </van-popup>

  </div>
</template>

<script>
// 移动端access_token，room_id，seat_id
import "@/assets/css/rank.css"
import "@/assets/css/pk.css"
import { charmList, clearPersonCharm, clearAllCharm } from "@/api/pkCharm";

export default {
  name: 'charmList',
  data() {
    return {
      charm_list: [],
      total_charm: [],
      show: false,
      is_display_button: null,
      isdisabled: false
    }
  },
  created() {
    let access_token = this.$route.query.access_token
    localStorage.setItem('access_token', access_token)
    this.fetchData()
  },
  // mounted(){
  //   this.fetchData()
  // },
  methods: {
    fetchData() {
      const Params = {
        room_id: this.$route.query.room_id,
        seat_id: this.$route.query.seat_id
      }
      charmList(Params).then(response => {
        this.is_display_button = response.data.is_display_button
        this.charm_list = response.data.charm_list
        this.total_charm = response.data.total_charm
      },
        error => {
          this.$toast(error)
        }).catch((error) => {
          this.$toast(error)
        })
    },
    showPopup() {
      if (this.charm_list.length == 0) {
        return false
      }
      this.show = true;
    },
    hidePopup() {
      this.show = false;
    },
    // 清空全部魅力值
    clearAll() {
      this.isdisabled = true
      let param = {
        room_id: this.$route.query.room_id,
        seat_id: this.$route.query.seat_id
      }
      clearAllCharm(param).then(response => {
        this.isdisabled = false
        // this.charm_list.splice(type,1)
        // this.total_charm = response.total_charm
        // this.$toast(response.msg)
        this.show = false
        this.fetchData()

      },
        error => {
          this.show = false
          this.isdisabled = false
          this.$toast(error)
        }).catch((error) => {
          this.show = false
          this.isdisabled = false
          this.$toast(error)
        })
    },
    // 清空某个人魅力值
    clearOne(uid) {
      this.isdisabled = true
      let param = {
        room_id: this.$route.query.room_id,
        seat_id: this.$route.query.seat_id,
        user_id: uid
      }
      clearPersonCharm(param).then(response => {
        console.log(response)
        this.isdisabled = false
        this.show = false
        this.fetchData()

      },
        error => {
          this.show = false
          this.isdisabled = false
          // this.$toast(error)
        }).catch((error) => {
          this.show = false
          this.isdisabled = false
          // this.$toast(error)
        })
    },
    checkUser(id) {
      let param = id.toString()
      this.$bridge.callhandler('checkUser', param, (data) => {
        // 处理返回数据
      })
    }

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.van-button--normal {
  font-size: 34px;
}

.van-button--default {
  height: 108px;
  color: rgba(40, 41, 44, 1);
  background-color: #fff;
  border: none;
}</style>
